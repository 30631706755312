import React from 'react';
import { deviceWidth } from '@/utils/deviceWidth';
import { Card1, Card2 } from '../collectionCards';
import router from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import eventLogger from '@/utils/eventLogger';
import { useSSRSelector } from '@/redux/ssrStore';
import { COLLECTION_CARD_ANIMATION_TYPE } from '@/utils/constants';
import { EVENT_NAMES } from '@/constants/common';

const CollectionWidget1 = (props) => {
  // const [widgetConfig, setWidgetConfig] = useState(props.config || null);

  const { collections, storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    collections: state.catalogReducer.collections,
  }));

  const widgetConfig = props.config;
  const storeInfo = storeData?.store_info;
  const animationTypeIndex = storeData?.theme?.collection_animation || 0;
  const animationType = COLLECTION_CARD_ANIMATION_TYPE[animationTypeIndex];

  // useEffect(() => {
  //   if (props?.config && Object.keys(props.config)) {
  //     setWidgetConfig(props.config);
  //   }
  // }, [props?.config]);

  const ifMax5Collections = () => {
    return collections?.length <= 5;
  };

  const handleCollectionCardClick = (collection) => {
    eventLogger(
      EVENT_NAMES.COLLECTION_WIDGET_CARD_CLICK,
      { collectionId: collection.id },
      true
    );
    router.push(getRoute(PATH.collectionCategories(collection.id), storeInfo?.domain));
  };

  /**
   * render specific collection card from the given prop
   * CollectionWidget1 -> Card1
   *
   * Based on type of card type
   * different cards are rendered
   *
   * Aspect ratio of cards are calculated from the
   * aspect_ratio prop from BE
   *
   * @param {Object} data
   * @param {number} idx
   * @param {number} cardType
   * @return {JSX} component from switch case
   */
  const _renderCollectionCard = (data, idx, cardType) => {
    switch (cardType) {
      case 1:
        return (
          <Card1
            widgetConfig={widgetConfig}
            data={data}
            key={idx}
            onClick={() => handleCollectionCardClick(data)}
            animationType={animationType}
          />
        );
      case 2:
        return (
          <Card2
            widgetConfig={widgetConfig}
            data={data}
            key={idx}
            onClick={() => handleCollectionCardClick(data)}
            animationType={animationType}
          />
        );
    }
  };

  /**
   * returns the number of columns columns from
   * props.config based on the device width
   * @return {number} no of columns
   */
  const returnGridTemplateColumns = () => {
    if (deviceWidth > 786 && ifMax5Collections()) return {};
    return deviceWidth <= 786
      ? widgetConfig?.mobile?.grid?.cols
      : widgetConfig?.desktop?.grid?.cols;
  };

  /**
   * returns the grid gap value based on
   * deviceWidth
   * @return {number} grid gap value
   */
  const returnGridSpacing = () => {
    if (deviceWidth > 786 && ifMax5Collections()) return 'auto';
    return deviceWidth <= 786
      ? widgetConfig?.mobile?.spacing || 20
      : widgetConfig?.desktop?.spacing || 20;
  };

  const bodyStyles = () => {
    if (deviceWidth <= 786) {
      return {
        gridTemplateColumns: `repeat(${returnGridTemplateColumns()}, 1fr)`,
        gridGap: `${returnGridSpacing()}px`,
      };
    } else {
      return {
        gridTemplateColumns: !ifMax5Collections()
          ? `repeat(${returnGridTemplateColumns()}, 1fr)`
          : null,
        gridGap: !ifMax5Collections() ? `${returnGridSpacing()}px` : null,
      };
    }
  };

  return (
    <section className="collectionWidget1">
      <h2 className="collectionWidget1--header">
        {widgetConfig?.heading || 'Shop By Collections'}
      </h2>
      <div
        className={`collectionWidget1__body ${
          deviceWidth > 786 && ifMax5Collections() ? 'horizontal-view' : 'grid-view'
        }`}
        style={bodyStyles()}
      >
        {collections?.length
          ? collections?.map((data, index) => {
              return _renderCollectionCard(data, index, props.cardType);
            })
          : null}
      </div>
    </section>
  );
};

export default CollectionWidget1;
