import React from 'react';

const DEFAULT_IMAGE_FOR_COLLECTION =
  'https://cdn.dotpe.in/longtail/store-items/default_image.png';

export const Card1 = ({ data, widgetConfig, onClick, animationType }) => {
  return (
    <div className="cardWidget cardWidget1" onClick={onClick}>
      <div
        className={`cardWidgetImage animation-${animationType}`}
        style={{
          backgroundImage: `url("${data?.image_url || DEFAULT_IMAGE_FOR_COLLECTION}")`,
          paddingTop: `${widgetConfig?.aspect_ratio}%`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      ></div>
      <p className="mv0 cardWidgetText">{data?.name || 'Available'}</p>
    </div>
  );
};

export const Card2 = ({ data, widgetConfig, onClick, animationType }) => {
  return (
    <div className="cardWidget cardWidget2" onClick={onClick}>
      <div className="overflow-hidden">
        <div
          className={`cardWidgetImage animation-${animationType}`}
          style={{
            backgroundImage: `url("${data?.image_url || DEFAULT_IMAGE_FOR_COLLECTION}")`,
            paddingTop: `${widgetConfig?.aspect_ratio}%`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
      <p className="mv0 cardWidgetText">{data?.name || 'Available'}</p>
    </div>
  );
};
